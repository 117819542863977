import firebase from 'firebase/compat/app';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAOKZfKvBE8XmRYtz70i-_PsKME4FfUwP4",
    authDomain: "back-events-96628.firebaseapp.com",
    projectId: "back-events-96628",
    storageBucket: "back-events-96628.appspot.com",
    messagingSenderId: "153459826832",
    appId: "1:153459826832:web:c3b04f01ff7af677edb815",
    measurementId: "G-GPW5MXDGBK"
});

// const firestore = firebaseApp.firestore();
// const storage = getStorage(firebaseApp)

export default { firebaseApp }


<template>
    <div style="height: 300px;">
        <img id="card_img" :src="_url" alt="">
        <!-- @click="$router.push(this.url_redirect)" -->
        <div class="bottom-section-container"  v-on:click="goTo()">
            <span id="title">{{ title }}</span>
            <i style="transform: scale(1.5);" class="bi bi-plus-lg"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeCard',
    props: {
        type: String,
        title: String,
        url: String,
    },
    data() {
        return {
            _url: String,
            url_redirect: this.url,
        }
    },
    created() {
        switch (this.type) {
            case "mobilier":
                this._url = require("@/assets/img/Tentes crystal/IMG_1851.jpg")
                break;
            case "amenagement":
                this._url = require("@/assets/img/TentesNomades/IMG_1491.jpg")
                break;
            case "elcairageson":
                this._url = require("@/assets/img/eclairage son/eclairage_son_1.jpg")
                break;
            case "chauffage":
                this._url = require("@/assets/img/chauffage/IMG_2478.jpg")
                break;
            default:
                break;
        }
    },
    methods: {
        goTo: function()
        {
            this.$router.push({ name: 'offer', params: { offer_type: this.url_redirect } })
        }
    }
}
</script>

<style scoped lang="less">

#title {
    color: white !important;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 20px;
}

.bottom-section-container {
    position: absolute;
    text-decoration: none;
    width: 90%;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    cursor: pointer;
}

.bi {
    color: white !important;
}

#card_img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
</style>

<template>
    <b-container>
        <b-row>
            <b-col xs="0" lg="12">
                <BeP3 />
            </b-col>
        </b-row>
        <b-row style="margin-bottom: 50px;">
            <b-col xs="0" lg="2"></b-col>
            <b-col xs="6">
                <h1 style="font-weight: bold; margin-top: 25px;">
                    La combinaison du savoir-faire au service de votre événement.
                </h1>
            </b-col>
            <b-col id="_p3_subtitle" xs="0" lg="5">
                <span>Plus qu'un métier, une passion.</span>
            </b-col>
        </b-row>
        <b-row style="margin: 0px 50px;" class="_marginMobile">
            <b-col style="padding: 10px 10px;" xs="6" md="12" lg="6" data-aos="fade-up" data-aos-delay="0"
                data-aos-duration="1000">
                <HomeBigCard title="Tentes & Structures" />
            </b-col>
            <b-col xs="12" md="12" lg="6">
                <b-row>
                    <b-col style="padding: 10px 10px;" xs="6" md="6" lg="6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
                        <HomeCard type="mobilier" title="Mobilier" url="mobilier"/>
                    </b-col>
                    <b-col style="padding: 10px 10px;" xs="6" md="6" lg="6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
                        <HomeCard type="amenagement" title="Aménagement" url="amenagement" />
                    </b-col>
                    <b-col style="padding: 10px 10px;" xs="6" md="6" lg="6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
                        <HomeCard type="elcairageson" title="Eclairage & Son" url="eclairageson" />
                    </b-col>
                    <b-col style="padding: 10px 10px;" xs="6" md="6" lg="6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
                        <HomeCard type="chauffage" title="Chauffage & Sol" url="chauffage" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import HomeCard from '@/components/home/cards/HomeCard.vue'
import HomeBigCard from '@/components/home/cards/HomeBigCard.vue'
import BeP3 from '@/components/home/UI/BeP3.vue'

export default {
    name: 'HomePage3',
    components: {
        HomeCard,
        HomeBigCard,
        BeP3,
    }
}
</script>

<style scoped lang="less">
#_p3_subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-style: italic;
}


@media (max-width: 995px) {
    ._marginMobile {
        margin: 0 !important;
    }
}
</style>
<template>
    <div id="lastr-c-main">
        <CarouselCard :btn_content="_btn_content" :btn_url="_btn_url" />
    </div>
</template>

<script>
import CarouselCard from '@/components/home/cards/CarouselCard.vue'

export default {
    name: 'LastrCard',
    props: {
        _btn_content: String,
        _btn_url: String,
    },
    components: {
        CarouselCard,
    },
    data() {
        return {
            index: 0,
        }
    },
    created() {
        this.index = 0
    },
    mounted: function () {
        // if (this.$route.name == 'home') {
        //     window.setInterval(() => {
        //         let els = document.getElementById("lastr-c-main").getElementsByTagName('img')
        //         if (this.index > els) this.index = 0
        //         document.getElementById(`slide1`).click()
        //         this.index++
        //     }, 3000)
        // }
    }
}
</script>

<style scoped lang="less">
#lastr-c-main {
    height: 100%;
    width: 100%;
}
</style>

<template>
    <b-container>
        <b-row>
            <b-col xs="12" lg="5">
                <h2 id="lastr-title">Dernières réalisations</h2>
            </b-col>
            <b-col xs="0" lg="7">
                <BeP5 />
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" lg="6">
                <CarouselCard btn_content="Découvrir" btn_url="offers" />
            </b-col>
            <b-col style="margin-top: 150px;" xs="12" lg="6">
                <CarouselCard2 btn_content="Découvrir" btn_url="offers" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import LastrCard from '@/components/home/cards/LastrCard.vue'
import CarouselCard from '@/components/home/cards/CarouselCard.vue'
import CarouselCard2 from '@/components/home/cards/CarouselCard2.vue'
import BeButton from '@/components/BeButton.vue'
import BeP5 from '@/components/home/UI/BeP5.vue'

export default {
    name: 'HomePage5',
    components: {
        LastrCard,
        BeButton,
        CarouselCard,
        CarouselCard2,
        BeP5,
        
    }
}
</script>

<style scoped lang="less">
#lastr-title {
    margin-bottom: 50px;
    margin-top: 75px;
    font-size: 40px;
    font-weight: bold;
}
</style>
  
<template>
    <a id="offer-card-container" :href="_url_redirect">
        <img id="offer-card-img" :src="_url_pic" alt="">
        <!-- {{ _url_pic }} -->
        <h1 id="offer-card-title">{{ _title }}</h1>
        <p id="offer-card-description">{{ _description }}</p>
    </a>
</template>

<script>
export default {
    name: 'OfferCard',
    data() {
        return {
            // parsedUrl: this._url_pic,
        }
    },
    props: {
        _url_pic: "",
        _title: "",
        _description: "",
        _url_redirect: "",
    },
}
</script>

<style>
a {
    text-decoration: none;
}
#offer-card-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
}
#offer-card-img {
    width: 100%;
    height: 100%;
}
#offer-card-title {
    color: #000000;
    font-weight: bold;
    font-size: 30px;
}
#offer-card-description {
    color: #000000;
}
</style>
<template>
    <b-container class="contact-main">
        <PageTitle title="Contact" />
        <b-row>
            <b-col style="margin-top: 50px;" xs="0" md="6" lg="6">
                <b-row>
                    <b-col xs="12" lg="12">
                        <div id="contact_left_img"></div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col style="margin-top: 50px;" id="be_contact_container" xs="12" md="6" lg="6">
                <form id="contact_form" ref="form" @submit.prevent="sendEmail">
                    <div>
                        <label>Prénom & nom</label>
                        <input id="contact_form_name" v-model="$data._name" type="text" name="user_name"><br>
                    </div>
                    <div>
                        <label>Ville</label>
                        <input id="contact_form_town" v-model="$data._town" type="text" name="user_town"><br>
                    </div>
                    <div>
                        <label>Numéro de téléphone</label>
                        <input id="contact_form_phone" v-model="$data._phone" type="text" name="user_phone"><br>
                    </div>
                    <div>
                        <label>Email</label>
                        <input id="contact_form_email" v-model="$data._email" type="email" name="user_email"><br>
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea id="contact_form_message" v-model="$data._message" name="message"></textarea><br>
                    </div>
                    <input id="contact_form_submit" type="submit" value="Envoyer">
                </form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import emailjs from '@emailjs/browser';
import PageTitle from '@/components/PageTitle.vue'

export default {
    name: 'ContactPage',
    components: {
        PageTitle,
    },
    data() {
        return {
            _name: '',
            _town: '',
            _phone: '',
            _email: '',
            _message: '',
            isContactPage: false
        }
    },
    methods: {
        sendEmail() {
            let _el_name = document.getElementById('contact_form_name')
            let _el_town = document.getElementById('contact_form_town')
            let _el_phone = document.getElementById('contact_form_phone')
            let _el_email = document.getElementById('contact_form_email')
            let _el_message = document.getElementById('contact_form_message')
            let result = window.confirm('Confirmer ?')
            if (result) {
                emailjs.sendForm('service_03exa2d', 'template_gjrpwar', this.$refs.form, 'MuCdvJeF4rWY7BmG3')
                    .then((result) => {
                        console.log('SUCCESS!', result.text);
                        alert("Mesage send succefuly !")
                        _el_name.value = ''
                        _el_town.value = ''
                        _el_phone.value = ''
                        _el_email.value = ''
                        _el_message.value = ''
                    }, (error) => {
                        console.log('FAILED...', error.text);
                        alert("Mesage was not delivered. Try again. If the probleme persists, please send us an email or contact us by phone. Thank you for your understanding.")
                    });
            };

        },
        switchScroll() {
            window.removeEventListener('DOMMouseScroll', preventDefault, false);
            window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
            window.removeEventListener('touchmove', preventDefault, wheelOpt);
            window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.switchScroll();
    },
}
</script>

<style lang="less">
li {
    list-style-type: none;
}

.contact-main-container {
    width: 100vw;
    height: 100vh;
}

#be_contact_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

input,
textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;

    margin-bottom: 30px;
    color: rgb(0, 0, 0);

    &:focus {
        outline: none;
    }
}

#contact_form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#contact_form_submit {
    background-color: #ffffff;
    border: 1px solid black;
    width: 100px;
    padding: 10px 10px;
}

#contact_whitep {
    margin-top: 50px;
}

#contact_left_img {
    height: 60vh;
    background-image: url('@/assets/home/1.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale();
    background-size: cover;
}
</style>
  
<template>
    <div id="_home">
        <div class="carousel-main">
            <Carousel />
        </div>
        <div class="my-filter">
            <b-container>
                <b-row>
                    <b-col xs="12" lg="7"  class="leftcontent">
                        <h1 class="home_title">L'architecte</h1><h1 style="margin-top: -15px;" class="home_title">de vos événements.</h1>
                        <div class="subtitle-container">
                            <h6>Tentes - Structures - Aménagements - Décors - Eclairage<br>Son - Mobilier -
                                Chauffage -
                                Sol - Sanitaires</h6>
                        </div>
                    </b-col>
                    <b-col xs="0" lg="5">
                        <BeP1 />
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>

import Carousel from '@/components/home/Carousel.vue'
import BeP1 from '@/components/home/UI/BeP1.vue'

export default {
    name: 'HomeAccueil',
    components: {
        Carousel,
        BeP1,
    },
    created() {
        window.scrollTo(0, 0);
    },
}
</script>

<style scoped lang="less">
#_home {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: transparent;
}

.carousel-main {
    position: absolute;

}

.my-filter {
    position: relative;

    &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.655);
        position: absolute;
        height: 100vh;
        width: 100vw;
    }
}
 
.home_title {
    font-size: 50px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.subtitle-container {
    color: white;
}

.leftcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding: 0px;
}
</style>
  
<template>
    <div id="footer-main">
        <b-container fluid>
            <b-row>
                <b-col lg="1"></b-col>
                <b-col xs="12" lg="5">
                    <h2 style="font-weight: bold; width: 85%;">Donnez vie à vos envies, ça commence par ici !</h2>
                    <p style="width: 70%;">
                        Détaillez nous les grandes lignes de votre événement et les différentes options que vous
                        préconisez. Nous reviendrons vers vous le plus rapidement possible afin d’identifier votre
                        besoin et de savoir comment Back Events pourra y répondre.</p>
                </b-col>
                <b-col lg="1"></b-col>
                <b-col xs="12" lg="4">
                    <form ref="form" @submit.prevent="sendEmail">
                        <label>Nom</label>
                        <input id="contact_form_name" type="text" name="user_name">
                        <label>E-mail</label>
                        <input id="contact_form_email" type="email" name="user_email">
                        <label>Message</label>
                        <textarea id="contact_form_message" label="Message" type="textarea" rows="4"
                            name="message"></textarea>
                        <input id="_submit" type="submit" value="Envoyer">
                    </form>
                </b-col>
                <b-col lg="1"></b-col>
            </b-row>
            <b-row style="padding-top: 50px;">
                <b-col xs="12" lg="1"></b-col>
                <b-col xs="12" lg="2">
                    <div class="be-footer-logo"></div>
                </b-col>
                <b-col class="footer-p4" xs="12" lg="6"
                    style="display: flex; justify-content: flex-start; align-items: center;">
                    <!-- <h4>Nos offres</h4>
                  <a class="footer-link" href="">Tentes</a>
                  <a class="footer-link" href="">Mobilier</a>
                  <a class="footer-link" href="">Eclairage & Son</a>
                  <a class="footer-link" href="">Conception</a>
                  <a class="footer-link" href="">Sanitaire</a>
                  <a class="footer-link" href="">Sol</a> -->
                    <span>L'architecte de vos évenements</span>
                    <!-- <a class="footer-link" href="/realisations">
                      Réalisations
                  </a>
                  <a class="footer-link" href="/society">
                      Notre société
                  </a> -->
                </b-col>
                <b-col xs="12" lg="3" class="footer-p6">
                    <h4>Contact</h4>
                    <p class="footer-link">06 87 04 45 82</p><br>
                    <a href="contact@back-events.fr" class="footer-link">contact@back-events.fr</a><br>
                    <div class="footer-network">
                        <a class="footer-slink" href="https://instagram.com/backevents?igshid=YmMyMTA2M2Y=">
                            <i class="bi bi-instagram"></i>
                        </a>
                        <a class="footer-slink" href="https://m.facebook.com/backevents/">
                            <i class="bi bi-facebook"></i>
                        </a>
                        <a class="footer-slink" href="https://www.linkedin.com/company/back-events/">
                            <i class="bi bi-linkedin"></i>
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div id="_be__copyright_container">
            <span id="_copyright">Copyright © back-events 2023</span>
            <router-link class="footer-link" to="/mentionlegales">Mention Légales</router-link>
            <a href="https://www.victorsorais.fr" id="_vs_add">created by VSE</a>
        </div>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    name: 'BeFooter',
    methods: {
        async sendEmail() {
            let _el_name = document.getElementById('contact_form_name')
            let _el_email = document.getElementById('contact_form_email')
            let _el_message = document.getElementById('contact_form_message')
            let result = window.confirm('Confirmer ?')
            if (result) {
                emailjs.sendForm('service_03exa2d', 'template_gjrpwar', this.$refs.form, 'MuCdvJeF4rWY7BmG3')
                    .then((result) => {
                        alert("Mesage send succefuly !")
                        _el_name.value = ''
                        _el_email.value = ''
                        _el_message.value = ''
                    }, (error) => {
                        console.log('Fail to send message...', error.text);
                    });
            }
        },
    },
}
</script>

<style scoped lang="less">
#footer-main {
    background-color: #000000;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    bottom: 0;
    color: #ffffff;
}

.footer-link {
    text-decoration: none;
    color: #686868;
    margin-right: 10px;

    &:hover {
        color: #ffffff;
        transition-duration: 0.4s;
    }
}

.footer-slink {
    text-decoration: none;
    color: #ffffff;
    margin-right: 30px;
}

.p5-a {
    color: #ffffff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.footer-network {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

input,
textarea {
    width: 100%;
    border: none;
    background-color: #404040;
    margin-bottom: 30px;
    color: white;

    &:focus {
        outline: none;
    }
}

#_submit {
    width: 25%;
    float: right;
    height: 30px;
    background-color: transparent;
    border: 1px solid #ffffff;
    color: white;

    &:hover {
        transition-duration: 0.4s;
        background-color: #ffffff;
        color: black;
    }
}

.be-footer-form {
    height: 100%;
}

.be-footer-logo {
    height: 100%;
    width: 100%;
    background-image: url('@/assets/back-blanc.png');
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.footer-p5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


/* copyright */

#_be__copyright_container {
    padding-top: 10px;
    padding-bottom: 50px;
    border-top: 1px solid #313131;
    margin-top: 10px;
    font-size: 12px;
    color: #313131;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#_copyright {
    letter-spacing: 1px;
    color: #b0b0b0;
}

#_vs_add {
    float: right;
    text-decoration: none;
    letter-spacing: 1px;
    color: #d3d3d3;
    animation: out 0.4s forwards;
}

#_vs_add:hover {
    animation: in 0.4s forwards;
}

@keyframes out {
    from {
        color: #ffffff;
    }

    to {
        color: #b0b0b0;
    }
}

@keyframes in {
    from {
        color: #b0b0b0;
    }

    to {
        color: #ffffff;
    }
}
</style>

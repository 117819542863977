<template>
    <div>
        <b-carousel class="be-carousel-1" id="carousel-1" v-model="slide" :interval="5000" indicators @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd">
            <b-carousel-slide class="slide1"></b-carousel-slide>
            <b-carousel-slide class="slide2"></b-carousel-slide>
            <b-carousel-slide class="slide3"></b-carousel-slide>
            <b-carousel-slide class="slide4"></b-carousel-slide>
            <b-carousel-slide class="slide5"></b-carousel-slide>
            <b-carousel-slide class="slide6"></b-carousel-slide>
            <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
        </b-carousel>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            slide: 0,
            sliding: null
        }
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }
}
</script>

<style>
.be-carousel-1 {
    height: 100vh;
    overflow: hidden;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.slide1 {
    height: 100vh;
    width: 100vw;
    background-image: url('@/assets/home/1.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide2 {
    height: 100vh;
    width: 100vw;
    background-image: url('@/assets/home/3.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide3 {
    height: 100vh;
    width: 100vw;
    background-image: url('@/assets/home/4.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide4 {
    height: 100vh;
    width: 100vw;
    background-image: url('@/assets/home/5.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide5 {
    height: 100vh;
    width: 100vw;
    background-image: url('@/assets/home/6.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide6 {
    height: 100vh;
    width: 100vw;
    background-image: url('@/assets/home/7.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
</style>
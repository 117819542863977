<template>
    <div class="_responsive">
        <div id="scertical-line-container">
            <div id="sc3_line1"></div>
            <div id="sc3_line2"></div>
            <div id="sc3_line3"></div>
            <div id="sc3_line4"></div>
            <span id="sc3_span">Donnez vie à vos envie.</span>
            <div id="sc3_square"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'beS3',
}
</script>

<style>
#scertical-line-container {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

#sc3_line1 {
    width: 350px;
    border-bottom: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 45%;
    right: 0;
    z-index: 3;
}

#sc3_line2 {
    width: 230px;
    border-bottom: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 90%;
    right: 0;
}

#sc3_line3 {
    width: 1000px;
    border-bottom: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 120%;
    right: 0;
    z-index: 3;
}
#sc3_line4 {
    height: 500px;
    border-left: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 30%;
    right: 35%;
    z-index: 3;
}
#sc3_span {
    bottom: -20%;
    left: 20%;
    position: absolute;
    font-style: italic;
}
#sc3_square {
    position: absolute;
    height: 40px;
    width: 200px;
    left: 41%;
    top: 41%;
    z-index: 1;

    background-color: #90D0E1;
}
</style>
<template>
    <div id="notfound-main">
        <h1 id="notfound-t">404</h1>
        <span id="notfound-st">Not Found...</span>
    </div>
</template>

<script>
export default {
    name: 'NotFound',

}
</script>

<style>
#notfound-main {
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#notfound-t {
    font-size: 150px;
    font-weight: bold;
    color: #000000;
}

#notfound-st {
    font-size: 40px;
    font-weight: 100;
    color: #000000;
}
</style>
<template>
    <div class="_responsive">
        <div id="horizontal-line-container">
            <div id="h_line1"></div>
            <div id="h_line2"></div>
        </div>
        <div id="_be_ui_square"></div>
        <div id="vertical-line-container">
            <div id="v_line1"></div>
            <div id="v_line2"></div>
            <div id="v_line3"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BeP2',
}
</script>

<style>
#_be_ui_square {
    position: absolute;
    height: 300px;
    width: 100px;
    right: 11%;
    top: 30%;
    background-color: #90D0E1;
    z-index: 2;
}

#horizontal-line-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    z-index: 5;
}

#h_line1 {
    width: 400px;
    position: absolute;
    border-top: 1px solid black;
    top: 20%;
}

#h_line2 {
    width: 280px;
    top: 60%;
    position: absolute;
    border-top: 1px solid black;
}

#vertical-line-container {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

#v_line1 {
    height: 270px;
    border-left: 1px solid black;
    position: absolute;
    left: 70%;
    top: -8.5%;
}

#v_line2 {
    height: 600px;
    border-left: 1px solid black;
    position: absolute;
    top: -8.5%;
}

#v_line3 {
    height: 300px;
    border-left: 1px solid black;
    position: absolute;
    left: 30%;
    top: -8.5%;
}
</style>
<template>
    <div class="_responsive" id="bep5_ui_container">
        <div id="bep5_ui_h_line"></div>
        <div id="bep5_ui_h_line1"></div>
        <div id="bep5_ui_v_line2"></div>
    </div>
</template>

<script>
export default {
    name: 'BeP5',
}
</script>

<style>
#bep5_ui_container {
    width: 100%;
    height: 100%;
    position: absolute;
}

#bep5_ui_h_line {
    width: 100%;
    border-bottom: 1px solid black;
    position: absolute;
    bottom: 50%;
}

#bep5_ui_h_line1 {
    position: absolute;
    width: 130px;
    border-bottom: 1px solid black;
    left: 60%;
    bottom: 0;
}

#bep5_ui_v_line2 {
    position: absolute;
    height: 100%;
    border-right: 1px solid black;
    left: 70%;
}

</style>
<template>
    <b-container>
        <PageTitle title="Nos offres" />
        <b-row>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('nomade')">
                    <div class="offer_card_img" id="offer_card_img_nomade"></div>
                    <h1 class="offer_card_title">Tentes Nomades</h1>
                    <p class="offer_card_description">Cette structure de réception élégante, flexible et légère s’adapte
                        à tous types d’environnement.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('crystal')">
                    <div class="offer_card_img" id="offer_card_img_crystal"></div>
                    <h1 class="offer_card_title">Tentes Cristal</h1>
                    <p class="offer_card_description">
                        La tente cristal permet à la structure de se fondre dans le paysage et d’être en harmonie avec
                        l’environnement extérieur.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('legere')">
                    <div class="offer_card_img" id="offer_card_img_legere"></div>
                    <h1 class="offer_card_title">Tentes Legères</h1>
                    <p class="offer_card_description">Ces tentes possèdent des attributs particuliers et facilitent
                        l’aménagement sur des espaces atypiques.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('velum')">
                    <div class="offer_card_img" id="offer_card_img_velum"></div>
                    <h1 class="offer_card_title">Tentes Velum</h1>
                    <p class="offer_card_description">Il apporte chic, élégance et cocooning à votre réception ou
                        événement.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('structure')">
                    <div class="offer_card_img" id="offer_card_img_structres"></div>
                    <h1 class="offer_card_title">Structures</h1>
                    <p class="offer_card_description">Espaces de réception classique, modulables pour tous types
                        d’événement.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('mobilier')">
                    <div class="offer_card_img" id="offer_card_img_mobilier"></div>
                    <h1 class="offer_card_title">Mobilier</h1>
                    <p class="offer_card_description">
                        De toutes ses formes et de toutes couleurs.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('amenagement')">
                    <div class="offer_card_img" id="offer_card_img_amenagement"></div>
                    <h1 class="offer_card_title">Aménagement & Décor</h1>
                    <p class="offer_card_description">
                        Associations idéales de vos envies et de notre savoir-faire.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('sol')">
                    <div class="offer_card_img" id="offer_card_img_sol"></div>
                    <h1 class="offer_card_title">Sol</h1>
                    <p class="offer_card_description">De différentes sortes, plancher sur châssis soit nature ou
                        recouvert de moquette pour vos salons ou expositions.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('eclairageson')">
                    <div class="offer_card_img" id="offer_card_img_eclairageson"></div>
                    <h1 class="offer_card_title">Eclairage & Son</h1>
                    <p class="offer_card_description">Un événement des plus féérique grâce à l’association
                        complémentaire et indispensable du son et de la lumière.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('chauffage')">
                    <div class="offer_card_img" id="offer_card_img_chauffage"></div>
                    <h1 class="offer_card_title">Chauffage</h1>
                    <p class="offer_card_description">Un complément indispensable pour votre
                        confort.</p>
                </a>
            </b-col>
            <b-col xs="12" md="6" lg="4" data-aos="fade" data-aos-duration="1000">
                <a class="offer_card_a" @click="loadOffer('sanitaire')">
                    <div class="offer_card_img" id="offer_card_img_sanitaire"></div>
                    <h1 class="offer_card_title">Sanitaires</h1>
                    <p class="offer_card_description">Esthétiques et de nouvelle génération.</p>
                </a>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import OfferCard from '@/components/offer/OfferCard.vue'
import firebase from "@/plugins/google-firebase.js";
const db = firebase.firestore

export default {
    name: 'OfferPage',
    components: {
        OfferCard,
        PageTitle,
    },
    methods: {
        loadOffer(type) {
            this.$router.push({ name: 'offer', params: { offer_type: type } })
        },
    },
    created() {
        window.scrollTo(0, 0);
    },
}
</script>

<style scoped lang="less">
.offer_card_a {
    text-decoration: none;
    height: 100%;
    width: 100%;
    padding: 20px;

    &:hover {
        cursor: pointer;
    }
}

.offer_card_title {
    color: #000000;
    font-size: 25px;
    padding-top: 20px;
    font-weight: bold;
}

.offer_card_description {
    color: #000000;
}

/* IMG DIV ID BRUT (TEMPORARY) */

.offer_card_img {
    max-height: 350px;
    height: 50vh;
    width: 100%;
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#offer_card_img_crystal {
    background-image: url('@/assets/img/Tentes\ crystal/img_1.jpg');
}

#offer_card_img_velum {
    background-image: url('@/assets/img/Sol/IMG_3205.jpg');
}

#offer_card_img_nomade {
    background-image: url('@/assets/img/TentesNomades/tente_nomade1.jpg');
}

#offer_card_img_legere {
    background-image: url('@/assets/img/tentes_legeres/IMG_4061.jpg');
}

#offer_card_img_mobilier {
    background-image: url('@/assets/img/mobilier/mobilier_1.jpg');
}

#offer_card_img_amenagement {
    background-image: url('@/assets/img/amenagement/amenagemeent4.jpg');
}

#offer_card_img_sanitaire {
    background-image: url('@/assets/img/sanitaires/Wenge4.jpg');
}

#offer_card_img_sol {
    background-image: url('@/assets/img/Sol/sol1.jpg');
}

#offer_card_img_structres {
    background-image: url('@/assets/img/Structures/structure_1.jpg');
}

#offer_card_img_eclairageson {
    background-image: url('@/assets/img/eclairage son/IMG_4567.jpg');
}

#offer_card_img_chauffage {
    background-image: url('@/assets/img/chauffage/IMG_2478.jpg');
}
</style>

<template>
  <RealisationPage />
</template>
  
<script>
// @ is an alias to /src
import RealisationPage from '@/components/realisation/RealisationPage.vue'

export default {
  name: 'RealisationView',
  components: {
    RealisationPage,
  },

}
</script>
  
<template>
    <b-container>
        <b-row>
            <b-col xs="0" lg="0"></b-col>
            <b-col xs="12" lg="7">
                <h1 style="font-weight: bold;">La passion de l'événementiel depuis plus de 40 ans.</h1>
                <b-row data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
                    <b-col xs="12" lg="6">
                        <p>Back Events met son savoir-faire et sa créativité à votre
                            service afin de vous
                            aider à concrétiser tous vos événements.<br><br>
                            C'est notre réelle adaptation à vos besoins, notre réactivité ainsi que notre souplesse
                            de travail qui nous permettent de répondre d'une manière sûre et efficace à vos souhaits.
                            Nous nous impliquons dans l'élaboration et le suivi de vos projets en véritable
                            partenaire.</p>
                    </b-col>
                    <b-col xs="12" lg="6">
                        <p>Si vous le souhaitez, nous pouvons prendre en charge la mise en
                            oeuvre de votre projet
                            dans sa globalité, de sa conception à sa décoration.<br><br>
                            Avec vous, notre objectif est le long terme. Notre travail quotidien prouve que notre
                            souci est d'améliorer sans cesse nos prestations.<br><br>
                            De nombreux clients nous ont déjà accordé leur confiance, pourquoi pas vous ?</p>
                    </b-col>
                    <div style="height: 100px; margin-top: 70px; margin-left: 50px;">
                        <BeButton content="découvrir" url="offers"/>
                    </div>
                </b-row>
            </b-col>
            <b-col xs="0" lg="5">
                <BeP2 />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import BeP2 from '@/components/home/UI/BeP2.vue'
import BeButton from '@/components/BeButton.vue'

export default {
    name: 'HomePage2',
    components: {
        BeP2,
        BeButton,
    },
}
</script>

<style scoped lang="less">

</style>
  
<template>
    <div v-on:click="goTo()" id="be_button_container">
        <i style="transform: scale(1.5); margin-right: 10px;" class="bi-plus-lg"></i>
        <span>{{content}}</span>
    </div>
</template>

<script>
export default {
    name: 'BeButton',
    props: {
        content: String,
        url: String,
        color: String,
    },
    data() {
        return {
            _btn_color: "#000000",
            url_redirect: this.url
        }
    },
    methods: {
        goTo: function()
        {
            this.$router.push({ name: this.url_redirect})
        }
    }
}
</script>
<style scoped lang="less">
#be_button_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: flex-start;
    text-transform: uppercase;
    color: v-bind(_btn_color);
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>    
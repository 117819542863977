<template>
    <div>
        <img style="width: 100%; height: 50vh; object-fit: cover; margin-bottom: 150px;" src="@/assets/home/1.jpg"
            alt="">
        <b-container>
            <b-row>
                <b-col xs="12" md="9" lg="7">
                    <h1 class="sc_title">Plus qu'un métier, une passion.</h1>
                    <p>Depuis plus de 40 ans, Back Events a su s’imposer comme un incontournable de l’événementiel et de
                        la réception privée. Les équipes mettent leur savoir-faire et leur créativité à votre service
                        afin de vous aider à concrétiser tous vos événements.<br><br>Nous sommes à votre écoute pour
                        répondre à toutes vos attentes afin d’organiser et de sublimer vos réceptions, mariages, salons,
                        événements sportifs, privées ou culturels.
                        Nous vous orientons au mieux pour finaliser votre projet reprenant vos souhaits, vos besoins et
                        les options envisagées.
                    </p>
                </b-col>
                <b-col xs="12" md="3" lg="5">
                    <BeS1 />
                </b-col>
                <b-col xs="12" md="12" lg="5">
                    <BeS2 />
                </b-col>
                <b-col style="margin-top: 150px;" xs="12" md="12" lg="7">
                    <h1 class="sc_title">Un véritable partenaire.</h1>
                    <p>C’est notre réelle adaptation à vos besoins, notre réactivité ainsi que notre souplesse de
                        travail qui nous permettent de répondre d’une manière sûre et efficace à vos souhaits. Nous nous
                        impliquons dans l’élaboration et le suivi de vos projets en tant que véritable
                        partenaire.<br><br>Si vous le souhaitez, nous pouvons prendre en charge la mise en oeuvre de
                        votre projet dans sa globalité, de la conception à la décoration. Afin de valider les
                        contraintes environnementales, une reconnaissance du lieu est indispensable. C’est aussi
                        l’occasion de faire plus amplement connaissance et ainsi valider votre projet.</p>
                    <img style="height: 700px; width: 100%; object-fit: cover; margin-top: 50px;" src="@/assets/home/3.jpg" alt="">
                </b-col>
                <b-col style="margin-top: 150px;" xs="12" md="12" lg="7">
                    <h1 class="sc_title">L'expérience à votre service.</h1>
                    <p>Ces années d’expériences ont permis à notre société d’être implantée dans des lieux et sites
                        prestigieux tel que le Louvre, Versailles, Vaux-Le-Vicomte, mais aussi dans des villes aussi
                        emblématiques que Paris, Cannes, Saint Tropez ou encore Deauville.<br><br>Ce que nous voulons ?
                        Vous proposer la structure adaptée à votre événement et à votre budget en créant si vous le
                        souhaitez des aménagements et décorations sur mesure afin que la magie opère et que vous soyez
                        pleinement satisfait.
                        Avec vous, notre objectif est le long terme !<br><br>Notre travail quotidien prouve que notre
                        souci est d’améliorer sans cesse nos prestations.
                        Ces années d’expérience témoignent de la confiance que vous pouvez nous accorder.</p>
                </b-col>
                <b-col xs="12" md="12" lg="5">
                    <BeS3 />
                </b-col>
            </b-row>
        </b-container>
        <div style="height: 200px;"></div>
    </div>
</template>

<script>
import BeS1 from '@/components/society/UI/BeS1.vue'
import BeS2 from '@/components/society/UI/BeS2.vue'
import BeS3 from '@/components/society/UI/BeS3.vue'

export default {
    name: 'SocietyPage',
    components: {
        BeS1,
        BeS2,
        BeS3
    },
    created() {
        window.scrollTo(0, 0);
    },
}
</script>

<style scoped lang="less">
#society-image {
    height: 100%;
    width: 100%;
    background-image: url("@/assets/home/1.jpg");
    background-size: contain;
    background-repeat: no-repeat;

}

.sc_title {
    font-weight: bold;
}
</style>

<template>
  <b-navbar id="_navbar" class="be-navbar" toggleable="lg" fixed="top" variant="info">
    <b-navbar-brand style="margin-right: 110px; padding-left: 25px;" href="/"><img
        src="@/assets/navbar-logo-backevents.jpg" alt="Back-Events" height="75" width="75"></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item>
          <router-link class="be-nav-element" to="/">Accueil</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="be-nav-element" to="/offers">Nos offres</router-link>
        </b-nav-item>
        <!-- <b-nav-item>
          <router-link class="be-nav-element" to="/realisations">Réalisation</router-link>
        </b-nav-item> -->
        <b-nav-item>
          <router-link class="be-nav-element" to="/society">Notre société</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="be-nav-element be-nav-last-child" to="/contact">Contactez-nous</router-link>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <!-- <b-button v-b-popover.hover.top="'insta, facebook, linkedin, telephone'" title="Network here soon !">
              Hover Me
            </b-button> -->
          <i v-b-popover.hover.top="'06 87 04 45 82'" class="bi bi-telephone-fill"></i>
          <a class="be-navbar-a" href="https://instagram.com/backevents?igshid=YmMyMTA2M2Y=">
            <i class="bi bi-instagram"></i>
          </a>
          <a class="be-navbar-a" href="https://m.facebook.com/backevents/">
            <i class="bi bi-facebook"></i>
          </a>
          <a class="be-navbar-a" href="https://www.linkedin.com/company/back-events/">
            <i class="bi bi-linkedin"></i>
          </a>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'BeNavbar',
  data() {
    return {
      animationName: "nav_in"
    }
  },
}
</script>

<style scoped lang="less">
i {
  margin-right: 15px;
}

.be-navbar-a {
  text-decoration: none;
  color: black;
}

nav {
  padding: 0;

  .be-nav-element {
    font-size: 15px;
    font-weight: bold;
    color: #1f1f1f;
    padding: 5px 10px;

    &.router-link-exact-active {
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}



.be-nav-last-child {
  color: white !important;
  background-color: black;
  border-radius: 50px;
}

.be-navbar {
  background-color: #ffffff !important;
}

.be-nav-brand {
  font-weight: bold;
}
</style>
  
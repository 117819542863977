<template>
    <div>
        <b-carousel class="be-carousel-2" id="carousel-1" :interval="3000" @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd">
            <b-carousel-slide class="c2slide1"></b-carousel-slide>
            <b-carousel-slide class="c2slide2"></b-carousel-slide>
            <b-carousel-slide class="c2slide3"></b-carousel-slide>
            <b-carousel-slide class="c2slide4"></b-carousel-slide>
            <b-carousel-slide class="c2slide5"></b-carousel-slide>
            <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
        </b-carousel>
        <BeButton style="margin: 20px 0;" :content="btn_content" :url="btn_url" />
    </div>
</template>
  
<script>
import BeButton from '@/components/BeButton.vue'

export default {
    name: 'CarouselCard2',
    components: {
        BeButton,
    },
    props: {
        btn_content: String,
        btn_url: String,
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        }
    }
}
</script>

<style>
.be-carousel-2 {
    height: 100%;
    overflow: hidden;
}

.c2slide1 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/img/Tentes\ crystal/img_1.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.c2slide2 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/5.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.c2slide3 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/1.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.c2slide4 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/6.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.c2slide5 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/3.jpg');
    background-color: #070707;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
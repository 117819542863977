import '@babel/polyfill'
import 'mutationobserver-shim'

import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/google-firebase'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// UI
import AOS from 'aos'
import 'aos/dist/aos.css'

//SEO
import Meta from 'vue-meta'

// Serverless backend
// import { firestorePlugin } from 'vuefire'
// Vue.use(firestorePlugin)

Vue.config.productionTip = false

new Vue({
  Meta,
  router,
  store,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
